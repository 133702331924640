import React, { useState } from 'react'
import logo from '../assets/logoRodape.png';

interface INavbar{
    home: boolean;
    sobre: boolean;
    catalogo: boolean;
    contato: boolean;
}

const Navbar: React.FC<INavbar> = (props) => {
    const [open, setOpen] = useState<boolean>(false);

  return (  
    <nav className="fixed w-full h-100 bg-white border-gray-200 shadow-lg">
        <div className="w-[95vw] lg:w-[80vw] flex flex-wrap items-center justify-between mx-auto py-3">
            <a href="#header" className="h-full flex items-center space-x-3 rtl:space-x-reverse">
                <img 
                className='w-[280px]'
                alt='logo'
                src={logo}/>
            </a>
            <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" onClick={() => open ? setOpen(false) : setOpen(true)}>
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div className={`${open ? 'flex' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
                <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
                    <li>
                    <a href="#header" className={`block py-2 px-3 text-gray-900 ${props.home ? 'text-rose-900' : ''} md:hover:text-rose-900 rounded md:bg-transparent md:p-0`} aria-current="page" onClick={() => setOpen(false)}>Home</a>
                    </li>
                    <li>
                    <a href="#sobre" className={`block py-2 px-3 text-gray-900 ${props.sobre ? 'text-rose-900' : ''} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-rose-900 md:p-0`} onClick={() => setOpen(false)}>Sobre</a>
                    </li>
                    <li>
                    <a href="#catalogo" className={`block py-2 px-3 text-gray-900 ${props.catalogo ? 'text-rose-900' : ''} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-rose-900 md:p-0`} onClick={() => setOpen(false)}>Catálogo</a>
                    </li>
                    <li>
                    <a href="#contato" className={`block py-2 px-3 text-gray-900 ${props.contato ? 'text-rose-900' : ''} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-rose-900 md:p-0`} onClick={() => setOpen(false)}>Contato</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default Navbar