import logo from '../assets/footerLogo.png';
import { GrInstagram } from 'react-icons/gr';

const Footer = () => {

  return (
    <div className='w-screen h-100 lg:h-[350px] bg-slate-900 flex flex-col justify-center items-center'>
        <div className='w-[95vw] lg:w-[80vw] h-4/6 flex lg:flex-row flex-col items-center justify-around my-10'>
            <div className='lg:w-[525px] w-11/12 h-full flex flex-col lg:flex-row bg-slate-50 rounded-xl items-center justify-around lg:mb-0 mb-5'>
                <div className='lg:w-[150px]'>
                    <img
                    className='w-[260px] lg:w-[150px]'
                    src={logo}
                    alt='Footer Logo'/> 
                </div>

                <figure className='w-5/12 lg:mt-0 mt-8 h-[2px] lg:w-[2px] lg:h-4/6 bg-slate-900 rounded-full'/>

                <div className='w-[250px] my-10 flex flex-col justify-center lg:items-start items-center'>
                    <h1 className='lg:text-md lg:font-medium font-semibold'>Mooca - São Paulo</h1>
                    <h1 className='lg:text-md lg:font-medium font-semibold lg:my-3 my-4'>(11) 98258-1286</h1>
                    <a href="mailto:luizricardo@casaacunzo.com.br" className='lg:text-md lg:font-medium font-semibold'>luizricardo@casaacunzo.com.br</a>
                </div>
            </div>

            <div className='w-full lg:w-3/12 h-full mx-8 flex flex-col p-2 text-slate-50 lg:items-start items-center lg:mb-0 mb-8'>
                <h1 className='font-semibold text-2xl'>Atendimento</h1>
                <div className='lg:w-full mt-3 mb-6 mr-5'>
                    <p className='text-slate-300'>Segunda a Sexta</p>
                    <p className='text-slate-300'>08h ás 18h</p>
                </div>

                <div className='lg:w-full mt-3 mb-6 lg:ms-0 ms-3'>
                    <p className='text-slate-300'>Sábados e Domingos</p>
                    <p className='text-slate-300'>08h ás 18h</p>
                </div>
            </div>

            <div className='lg;w-3/12 h-full flex flex-col p-2 text-slate-50'>
                <h1 className='font-semibold text-2xl'>Nossas Redes</h1>
                <div className='w-full h-100 flex items-center mt-3'>
                    <GrInstagram className="text-2xl text-pink-800 flex"/><a href="https://www.instagram.com/casa_acunzo" className='ps-2 text-slate-300' target='_blank' rel="noreferrer">@casa_acunzo</a>
                </div>
            </div>
        </div>

        <div className='w-[75vw] lg:w-[60vw] h-100 flex justify-center items-center border-t border-slate-300 mb-4'>
            <h1 className='text-slate-200 font-medium pt-4 text-center lg:text-start'>Onde o lar dos sabores reside - Vinhos e bebidas de excelência</h1>
        </div>
    </div>
  )
}

export default Footer